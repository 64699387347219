// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage"
import "channels"

import('bootstrap-vue/dist/bootstrap-vue.css');
import jq from "jquery";
import * as bootstrap from 'bootstrap';

import React from 'react'
import ReactDOM from 'react-dom'

ActiveStorage.start()

window.addEventListener("load", function() {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var testMessageModal = document.getElementById('testMessageModal')

  if (testMessageModal !== null) {
    testMessageModal.addEventListener('show.bs.modal', function (event) {
      var button = jq(event.relatedTarget);
      var templateUrl = JSON.parse(button.data('template-url'));
      var modal = jq(this);
      modal.find('.modal-body').text( "Loading" );
      modal.find('.modal-body').load( templateUrl );
    })
  }

  jq('.a-prevent-default').click((function(t){t.preventDefault()}));

  var messageTextarea = document.getElementById('associated_message_message');

  if(messageTextarea !== null) {
    messageTextarea.oninput = handleInput;
    messageTextarea.dispatchEvent(new Event('input', { bubbles: true})); // Init Credit Count by doing an initial input bubble

    var currentAnchorType = jq("#associated_message_anchor_message_type").val();
    filterMessageTemplates(currentAnchorType);

    jq('body').on('input', '#associated_message_anchor_message_type', function (event) {
      var currentAnchorType = jq("#associated_message_anchor_message_type").val();
      filterMessageTemplates(currentAnchorType);
    });

    var collapseTemplateSelection = document.getElementById('collapseTemplateSelection')
    collapseTemplateSelection.addEventListener('shown.bs.collapse', function () {
      autoAdjustTextareaHeight();
    })

    var collapseTemplateSelection = document.getElementById('collapseTemplateSelection')
    var bscollapseTemplateSelection = new bootstrap.Collapse(collapseTemplateSelection, {toggle: false})

    jq('body').on('click', ".template-textarea-auto-adjust-height", function (event) {
      jq("#associated_message_message").val(jq(event.target).val());
      updateMessageCount();
      bscollapseTemplateSelection.hide()
    });

    var beforeUnloadSet = false;

    jq( "form :input" ).on( "input", function( event ) {
      if (beforeUnloadSet == false) {
        window.addEventListener('beforeunload', beforeUnloadEventHandler);
      }
      beforeUnloadSet = true;
    });

    jq( "form" ).submit(function( event ) {
      if (beforeUnloadSet == true)
        window.removeEventListener("beforeunload", beforeUnloadEventHandler);
    });
  }
})

function beforeUnloadEventHandler(event) {
  event.preventDefault();
  event.returnValue = '';
}

function filterMessageTemplates(anchorType) {
  jq(".template-textarea-auto-adjust-height[data-anchor-type='" + anchorType + "']").removeClass( "d-none" ).addClass( "d-block" );
  jq(".template-textarea-auto-adjust-height[data-anchor-type!='" + anchorType + "']").addClass( "d-none" ).removeClass( "d-block" );

  autoAdjustTextareaHeight();

  var noTemplateHeader = jq("#collapseTemplateSelection > h4");

  if(jq(".template-textarea-auto-adjust-height").hasClass("d-block") === false) {
    noTemplateHeader.removeClass( "d-none" ).addClass( "d-block" );
  } else {
    noTemplateHeader.removeClass( "d-block" ).addClass( "d-none" )
  }
}

function autoAdjustTextareaHeight() {
  jq('.template-textarea-auto-adjust-height').each(function() {
    jq(this).height(jq(this).prop('scrollHeight'));
  });
}

function handleInput(event) {
  setCreditCount(event.target.value)
}

function setCreditCount(messageText) {
  var size = 160;
  var messageLength = messageText.length;
  messageLength += (messageText.match(/\u00A3/g) || []).length;
  var placeholder_length = jq("#associated_message_message")[0].placeholder.length;
  messageLength = messageLength + placeholder_length;
  if(messageLength > 160)
    size = 154;
  var credits = Math.ceil(messageLength / size);
  if(credits === 0)
    credits++;
  var messageLimit = credits * size;
  var countCharactersText = "" + messageLength + "/" + messageLimit + ". Credits used: " + credits;
  jq("#countCharactersCredits").val(countCharactersText);
}

function updateMessageCount() {
  var messageTextarea = jq("#associated_message_message");
  messageTextarea[0].dispatchEvent(new Event('input', { bubbles: true}));
}

class Attachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {photoPreviewUrl: props.photo_preview, videoPreviewUrl: props.video_preview, photoUploading: false, videoUploading: false};

    this.photoRef = React.createRef();
    this.photoFormRef = React.createRef();
    this.videoRef = React.createRef();
    this.videoFormRef = React.createRef();
  }

  fileInputEmpty(fileInput) {
    if(fileInput.current === null)
      return true;
    return fileInput.current.files.length === 0;
  }

  handlePhotoChange = () => {
    if(this.fileInputEmpty(this.photoRef) === false)
      Rails.fire(this.photoFormRef.current, 'submit');
  }

  handleVideoChange = () => {
    if(this.fileInputEmpty(this.videoRef) === false)
      Rails.fire(this.videoFormRef.current, 'submit');
  }

  componentDidMount() {
    const here = this;
    this.photoFormRef.current.addEventListener('ajax:send', function(event) {
      here.photoRef.current.value = null;
      here.photoRef.current.dispatchEvent(new Event('change', { bubbles: true}));
      here.setState({photoUploading: true});
    });

    this.photoFormRef.current.addEventListener('ajax:success', function(event) {
      here.setState({photoPreviewUrl: event.detail[0]});
    });

    this.photoFormRef.current.addEventListener('ajax:error', function(event) {
      alert(event.detail[0]);
    });

    this.photoFormRef.current.addEventListener('ajax:complete', function(event) {
      here.setState({photoUploading: false});
    });

    this.videoFormRef.current.addEventListener('ajax:send', function(event) {
      here.videoRef.current.value = null;
      here.videoRef.current.dispatchEvent(new Event('change', { bubbles: true}));
      here.setState({videoUploading: true});
    });

    this.videoFormRef.current.addEventListener('ajax:success', function(event) {
      here.setState({videoPreviewUrl: event.detail[0]});
    });

    this.videoFormRef.current.addEventListener('ajax:error', function(event) {
      alert(event.detail[0]);
    });

    this.videoFormRef.current.addEventListener('ajax:complete', function(event) {
      here.setState({videoUploading: false});
    });
  }

  render() {
    let bs4Spinner = <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>;
    let isPhotoUploading = this.state.photoUploading === true;
    let isVideoUploading = this.state.videoUploading === true;

    return (
      <>
        <h2 className="text-center">Choose Photo and Video</h2>
        <div className="form-row">
          <form className="form-group col-md-6 text-center" action={this.props.photo_form_action} acceptCharset="UTF-8" method="post" data-remote="true" encType="multipart/form-data" ref={this.photoFormRef}>
          <input type="hidden" name="_method" value="patch" />
            <input type="hidden" name="authenticity_token" value={this.props.csrf_token} />
            <div className="form-group">
              <label htmlFor="business_card_photo" className="btn btn-primary"> {isPhotoUploading ? bs4Spinner : "Choose another image"}  </label>
              <input disabled={isPhotoUploading} className="form-control-file d-none" required="required" type="file" name="business_card[photo]" id="business_card_photo" ref={this.photoRef} onChange={this.handlePhotoChange} />
              <div className="invalid-feedback"></div>
            </div>
            <img src={this.state.photoPreviewUrl} className="mb-3 img-fluid" alt="Business Photo" />
          </form>

          <form className="form-group col-md-6 text-center" action={this.props.video_form_action} acceptCharset="UTF-8" method="post" data-remote="true" encType="multipart/form-data" ref={this.videoFormRef}>
            <input type="hidden" name="_method" value="patch" />
            <input type="hidden" name="authenticity_token" value={this.props.csrf_token} />
            <div className="form-group">
              <label htmlFor="business_card_video" className="btn btn-primary"> {isVideoUploading ? bs4Spinner : "Choose another video"}  </label>
              <input disabled={isVideoUploading} className="form-control-file d-none" required="required" type="file" name="business_card[video]" id="business_card_video" ref={this.videoRef} onChange={this.handleVideoChange} />
              <div className="invalid-feedback"></div>
            </div>
            <video preload="none" controls="controls" src={this.state.videoPreviewUrl} className="img-fluid"></video>
          </form>
        </div>
      </>
    );
  }
}

window.addEventListener('load', () => {
  var element = document.getElementById("business_card_image_video")
  if (element != null) {
    const data = JSON.parse(element.getAttribute('data'));
    ReactDOM.render(<Attachments {...data} />, element);
  }
});
